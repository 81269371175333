<script setup lang="ts">
import type { Blocks, HeroContentResponseContent } from 'types/index';

const props = defineProps<{
  blocks: Blocks;
  content: HeroContentResponseContent;
}>();

const computedBlocks = computed(() => {
  return props.blocks.blocks || {};
});
</script>

<template>
  <main>
    <BaseHero
      v-if="content.heroList"
      :data="content.heroList"
    />

    <BlockList
      v-if="blocks"
      :blocks="computedBlocks"
    />
  </main>
</template>
